import React, { useState } from 'react'
import { navigate } from 'gatsby'

import { Box, Flex, Heading, Image, Text, Button } from '@chakra-ui/react'
import { AnimateSharedLayout, AnimatePresence } from 'framer-motion'

import createMotionComponent, {
    FadeUpBox,
    FadeIn,
} from '~components/shared/custom-animation'

import design from '~images/backgrounds/design.jpg'
import develop from '~images/backgrounds/develop.jpg'
import deploy from '~images/backgrounds/deploy.jpg'
import maintain from '~images/backgrounds/maintain.jpg'

const MotionFlex = createMotionComponent(Flex)
const MotionBox = createMotionComponent(Box)

const ProcessBar = () => {
    const [selected, setSelected] = useState(1)

    const numbers = [1, 2, 3, 4]
    const colors = ['brand.300', 'brand.400', 'brand.500', 'brand.600']

    return (
        <Box>
            <FadeUpBox>
                <Flex
                    align="center"
                    direction="column"
                    display={{ base: 'none', lg: 'flex' }}
                >
                    {/* <Flex justify="center">
                        {numbers.map((number, index) => (
                            <Flex key={index} align="center">
                                <Circle
                                    number={number}
                                    selected={selected}
                                    setSelected={setSelected}
                                    bg={colors[number - 1]}
                                />
                                {number < 4 && <Line />}
                            </Flex>
                        ))}
                    </Flex> */}
                </Flex>
            </FadeUpBox>
            <FadeIn>
                <AnimateSharedLayout type="crossfade">
                    <MotionFlex
                        mt={{ base: 4, lg: 16 }}
                        bg="brand.400"
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        {numbers.map((number, index) => (
                            <Block
                                key={index}
                                number={number}
                                bg={colors[number - 1]}
                                selected={selected}
                                setSelected={() => setSelected(number)}
                            />
                        ))}
                    </MotionFlex>
                </AnimateSharedLayout>
            </FadeIn>
        </Box>
    )
}

const Block = ({ number, bg, selected, setSelected, ...props }) => {
    const isSelected = selected === number

    return (
        <MotionFlex
            layout
            cursor={isSelected || 'pointer'}
            onClick={setSelected}
            w={{ base: '100%', lg: isSelected ? '55%' : '15%' }}
            minHeight={{ base: isSelected ? '512px' : '108px', lg: '512px' }}
            justify="flex-end"
            align="center"
            bg={bg}
        >
            <AnimatePresence>
                <MotionFlex
                    key="Wow"
                    whileHover={{ y: -10 }}
                    boxSize={isSelected ? '0%' : '100%'}
                    align="center"
                    justify="center"
                    opacity={isSelected && '0'}
                >
                    <Heading
                        layoutid="blockHeader"
                        transition="0.5s"
                        fontSize="md"
                        color="white"
                    >
                        {
                            ['Design', 'Develop', 'Deploy', 'Maintain'][
                                number - 1
                            ]
                        }
                    </Heading>
                </MotionFlex>
                <MotionFlex
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    boxSize={isSelected ? '100%' : '0%'}
                    align="flex-start"
                    justify="center"
                    direction="column"
                >
                    {isSelected && <Content number={number} />}
                </MotionFlex>
            </AnimatePresence>
        </MotionFlex>
    )
}

function Content({ number }) {
    const backgrounds = [design, develop, deploy, maintain]
    const texts = [
        'Before we begin development, we plan out exactly how it will be done, ensuring we run into minimal obstacles as well as maximizing the finished product.',
        'Once our design has been perfected, we get right into the code to start making your vision a reality. We use our skillsets and modern technologies to develop efficiently until the product is perfect.',
        'Once development is complete, it is time to bring your product to light. Whether that be hosting it online or publishing it to an app store, we will handle getting your product to the world.',
        'Finally, we gladly maintain any product we create. If there is ever anything that does not match your vision, we stand ready to maintain the product over time as the industry and technologies change.',
    ]

    return (
        <MotionBox
            layout
            transition={{ delay: 0.3 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            p={8}
        >
            <Flex
                w="100%"
                h="100%"
                justify="center"
                align="center"
                direction={{ base: 'column', lg: 'row' }}
            >
                <Flex
                    direction="column"
                    w={{ base: '100%', lg: '48%' }}
                    justify="space-evenly"
                >
                    <Heading fontSize="lg" color="white" mb={5}>
                        {
                            ['Design', 'Develop', 'Deploy', 'Maintain'][
                                number - 1
                            ]
                        }
                    </Heading>
                    {/* <Box
                        mt={3}
                        mb={{ base: 2, lg: 6 }}
                        bg="white"
                        h="2px"
                        w="50%"
                    /> */}
                    <Text
                        color="white"
                        px={{ lg: 8 }}
                        py={{ base: 4, lg: 0 }}
                        borderLeft="2px solid white"
                        pl={8}
                    >
                        {texts[number - 1]}
                    </Text>
                    {number === 4 && (
                        <Box my={6}>
                            <Button
                                bg="white"
                                onClick={() => navigate('/about')}
                            >
                                Learn more about us.
                            </Button>
                        </Box>
                    )}
                </Flex>
                <Image
                    objectPosition="center"
                    objectFit="cover"
                    borderRadius="5%"
                    h="100%"
                    w={{ base: '100%', lg: '50%' }}
                    src={backgrounds[number - 1]}
                    boxShadow="lg"
                />
            </Flex>
        </MotionBox>
    )
}

const Circle = ({ number, selected, setSelected, bg }) => {
    const [hovered, setHovered] = useState(false)

    return (
        <Flex
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            align="center"
            justify="center"
            onClick={() => setSelected(number)}
            border="1px solid #aaa"
            bg={selected === number ? bg : hovered && bg}
            borderRadius="50%"
            w={{ base: '36px', lg: '56px' }}
            h={{ base: '36px', lg: '56px' }}
            transition="0.2s"
            cursor="pointer"
            userSelect="none"
        >
            <Heading
                fontSize={{ base: 'md', lg: 'lg' }}
                color={selected === number ? 'white' : hovered && 'white'}
                transition="inherit"
            >
                {number}
            </Heading>
        </Flex>
    )
}

const Line = () => {
    return (
        <Box
            w={['48px', '96px', '136px', '156px', '184px']}
            borderTop="2px dashed #aaa"
            h="1px"
        />
    )
}

export default ProcessBar

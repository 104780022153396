import React from 'react'
import { navigate } from 'gatsby'
import { Box, Flex, Heading, Text, Image, Button } from '@chakra-ui/react'

import bgImage from '~images/backgrounds/jigsaw.svg'
import Padlock from '~images/icons/padlock.svg'
import Price from '~images/icons/price.svg'
import ResponsiveBlock from '~components/shared/responsive-block'

const Guarantees = () => {
    return (
        <Box bg="gray.50">
            <ResponsiveBlock>
                <Flex
                    justify="space-between"
                    align={{ base: 'center', md: 'flex-start' }}
                    pt={125}
                    pb={100}
                    direction={{ base: 'column', md: 'row' }}
                >
                    <Flex
                        w={{ base: '100%', md: '45%' }}
                        h="100%"
                        justify="center"
                        align="center"
                        direction="column"
                        pb={{ base: 12, md: 0 }}
                    >
                        <Image src={Padlock} opacity={0.6} w="78px" />
                        <Heading
                            fontSize={'md'}
                            textAlign="center"
                            pt="20px"
                            pb="15px"
                            color="brand.400"
                        >
                            Our Quality Guarantee
                        </Heading>
                        <Text
                            fontSize={'sm'}
                            textAlign="center"
                            color="gray.600"
                        >
                            We are obsessed with your satisfaction. Because of
                            that, we guarantee that if you are not 100% happy
                            with the quality of your product once delivered, we
                            will iterate upon it at no cost until you are.
                        </Text>
                    </Flex>
                    <Flex
                        w={{ base: '100%', md: '45%' }}
                        h="100%"
                        justify="center"
                        align="center"
                        direction="column"
                    >
                        <Image src={Price} opacity={0.6} w="78px" />
                        <Heading
                            fontSize={'md'}
                            textAlign="center"
                            // color="white"
                            pt="20px"
                            pb="15px"
                            color="brand.400"
                        >
                            Our Price Guarantee
                        </Heading>
                        <Text
                            fontSize={'sm'}
                            textAlign="center"
                            color="gray.600"
                        >
                            We are committed to providing the best value to our
                            clients. If you are quoted a price better than ours
                            from any competing company, we will beat it.
                            Guaranteed.
                        </Text>
                    </Flex>
                </Flex>
                <Flex pb={75} align="center" direction="column">
                    <Heading fontSize={'lg'} textAlign="center" pb={5}>
                        Don't believe us?
                    </Heading>
                    <Button
                        variant="brand"
                        onClick={() => navigate('/contact')}
                    >
                        Call us on our bluff.
                    </Button>
                </Flex>
            </ResponsiveBlock>
        </Box>
    )
}

export default Guarantees

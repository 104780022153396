import React from 'react'

import { Box, Flex } from '@chakra-ui/react'

import { FadeUpBox } from '~components/shared/custom-animation'

const LineDivider = () => {
    return (
        <FadeUpBox>
            <Flex h="84px">
                <Box w="50%" borderRight="1px solid #cccccc" />
                <Box w="50%" borderLeft="1px solid #cccccc" />
            </Flex>
        </FadeUpBox>
    )
}

export default LineDivider

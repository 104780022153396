import React from 'react'
import { Center, Box, Flex, Heading, Text, Image } from '@chakra-ui/react'

import { FadeIn, FadeUpBox } from '~components/shared/custom-animation'

import wave from '~images/backgrounds/bottom_wave.svg'
import services from '~images/services/services.svg'

const ServicesHeader = () => {
    return (
        <Box
            h="80vh"
            bgImage={`url(${wave})`}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPos="center"
        >
            <Center w="100%" h="100%" align="center" justify="space-between">
                <Flex
                    w="100%"
                    h={{ base: '40%', md: '100%' }}
                    direction={{ base: 'column', md: 'row' }}
                    align="center"
                    justify="space-between"
                    px="10%"
                >
                    <Box
                        w={{ base: '100%', sm: '60%', md: '50%', lg: 96 }}
                        mb={{ base: 12, md: 0 }}
                    >
                        <FadeIn>
                            <Image
                                src={services}
                                w="100%"
                                h="100%"
                                objectFit="cover"
                            />
                        </FadeIn>
                    </Box>
                    <Box w={{ base: '100%', md: '45%', lg: '50%' }}>
                        <FadeUpBox>
                            <Heading
                                textAlign={{ base: 'center', md: 'left' }}
                                fontSize={{ base: 'lg', xl: '2xl' }}
                                lineHeight={{ base: '40px', xl: '60px' }}
                                mb={2}
                            >
                                With{' '}
                                <Box
                                    as="span"
                                    fontWeight={700}
                                    fontStyle="italic"
                                >
                                    you
                                </Box>{' '}
                                through every step of the project
                            </Heading>
                        </FadeUpBox>
                        <FadeUpBox>
                            <Text
                                textAlign={{ base: 'center', md: 'left' }}
                                fontSize={{ base: 'sm', xl: 'md' }}
                                color="gray.500"
                            >
                                We're here to help you with every aspect of
                                creating a web or mobile application.
                            </Text>
                        </FadeUpBox>
                    </Box>
                </Flex>
            </Center>
        </Box>
    )
}

export default ServicesHeader

import React from 'react'
import { navigate } from 'gatsby'
import { Box, Text, Flex, Heading, Button } from '@chakra-ui/react'

import ResponsiveBlock from '~components/shared/responsive-block'
import ComparisonBlock from '~components/services/comparison-block'
import LineDivider from '~components/services/line-divider'
import { FadeUpBox } from '~components/shared/custom-animation'

import speed from '~images/icons/speed.svg'
import price from '~images/icons/price.svg'
import quality from '~images/icons/quality.svg'
import control from '~images/icons/control.svg'

const Comparison = () => {
    return (
        <Box py={20}>
            <ResponsiveBlock>
                <FadeUpBox>
                    <Heading
                        fontSize={{ base: 'lg', md: 'xl' }}
                        align="center"
                        pb={6}
                    >
                        Why choose us?
                    </Heading>
                </FadeUpBox>
                <FadeUpBox>
                    <Text
                        fontSize={['sm', 'md']}
                        align="center"
                        pb={24}
                        color="gray.500"
                    >
                        We know there are a lot of developers our there, but we
                        pride ourselves on being the least expensive and most
                        personal firm in the industry. Just look below at how we
                        size up to our competitors.
                    </Text>
                </FadeUpBox>
                <Flex
                    // display={{ base: 'none', md: 'flex' }}
                    justify="space-evenly"
                    pb={6}
                >
                    <Flex w="33%" justify="center">
                        <FadeUpBox>
                            <Heading
                                display={{ base: 'none', md: 'flex' }}
                                w="100%"
                                fontSize="xl"
                                textAlign="center"
                            >
                                Them
                            </Heading>
                        </FadeUpBox>
                    </Flex>
                    <Box w="33%" />
                    <Flex w="33%" justify="center">
                        <FadeUpBox>
                            <Heading
                                display={{ base: 'none', md: 'flex' }}
                                fontSize="xl"
                                textAlign="center"
                            >
                                Us
                            </Heading>
                        </FadeUpBox>
                    </Flex>
                </Flex>
                <Flex direction="column" pb={'8%'}>
                    <ComparisonBlock
                        title="Price"
                        img={price}
                        leftLabel="Expensive"
                        leftText="Larger companies have huge overhead and bureaucracy, driving prices up"
                        rightLabel="Fair"
                        rightText="Our highly efficient and agile team keeps costs low, without sacrificing any quality"
                    />
                    <LineDivider />
                    <ComparisonBlock
                        title="Speed"
                        img={speed}
                        leftLabel="Sloppy"
                        leftText="Others may try to cut corners to save a few minutes, causing bugs, which delays production"
                        rightLabel="Careful"
                        rightText="Coding with quality the first time saves time and money in the long run, which we pass on to you"
                    />
                    <LineDivider />
                    <ComparisonBlock
                        title="Quality"
                        img={quality}
                        leftLabel="Unreliable"
                        leftText="Some companies try to get projects over with quickly and the result may be sloppy"
                        rightLabel="Consistent"
                        rightText="We pay close attention to details to deliver an excellent product every time"
                    />
                    <LineDivider />
                    <ComparisonBlock
                        title="Control"
                        img={control}
                        leftLabel="Distant"
                        leftText="Others may be impossible to contact and will not keep you updated on the progress of your product"
                        rightLabel="Collaborative"
                        rightText="We will work closely with you to produce a product exceeding your expectations"
                    />
                </Flex>
            </ResponsiveBlock>
        </Box>
    )
}

export default Comparison

import React from 'react'

import { Text, Box, Heading } from '@chakra-ui/react'

import ResponsiveBlock from '~components/shared/responsive-block'
import ProcessBar from '~components/services/process-bar'
import { FadeUpBox } from '~components/shared/custom-animation'

import wave from '~images/backgrounds/wave_top.svg'

const Process = () => {
    return (
        <Box
            bgImage={`url(${wave})`}
            bgSize="contain"
            bgRepeat="no-repeat"
            bgPosition="top"
            pt={32}
            pb={{ base: 8, md: 24 }}
        >
            <ResponsiveBlock>
                <FadeUpBox>
                    <Heading
                        fontSize={['lg', 'xl']}
                        pt={16}
                        pb={8}
                        align="center"
                    >
                        Our Process
                    </Heading>
                    <Text
                        align="center"
                        fontSize={['sm', 'md']}
                        color="gray.500"
                    >
                        Here's our roadmap to success. Explore the slides below
                        to see our process every step of the way and see how
                        easy it can be to get an amazing application done with
                        Breach.
                    </Text>
                </FadeUpBox>
            </ResponsiveBlock>
            <ProcessBar />
        </Box>
    )
}

export default Process

import React from 'react'
import {
    Box,
    Flex,
    Heading,
    Text,
    Image,
    Grid,
    GridItem,
    useTheme,
    useBreakpointValue,
} from '@chakra-ui/react'

import ResponsiveBlock from '~components/shared/responsive-block'
import CreateMotionComponent, {
    FadeUpBox,
} from '~components/shared/custom-animation'

import newSite from '~images/services/new_site.svg'
import redesign from '~images/services/redesign.svg'
import ios from '~images/services/ios.svg'
import android from '~images/services/android.svg'
import cross from '~images/services/cross.svg'
import ethereum from '~images/services/ethereum.svg'

const ServicesWhat = () => {
    const delay1 = useBreakpointValue({ base: 0, md: 0.3 })
    const delay2 = useBreakpointValue({ base: 0, md: 0.6 })

    return (
        <Box bg="gray.50" pb={20}>
            <ResponsiveBlock>
                <Flex
                    justify="center"
                    align="center"
                    direction="column"
                    w="100%"
                >
                    <Flex
                        justify="center"
                        align="center"
                        direction="column"
                        pb={12}
                    >
                        <FadeUpBox>
                            <Heading
                                fontSize={['lg', 'xl']}
                                textAlign="center"
                                mb={3}
                            >
                                What We Do Best
                            </Heading>
                            <Text
                                fontSize={['sm', 'md']}
                                textAlign="center"
                                color="gray.600"
                            >
                                We leverage or small team size to stay flexible
                                and agile in today's fast paced environment.
                                Without bureaucracy, our team responds to
                                requests and communications at lightspeed, and
                                the technologies we use are ever changing.
                            </Text>
                        </FadeUpBox>
                    </Flex>
                    <Grid
                        w="100%"
                        gap={8}
                        templateColumns={[
                            'repeat(1, 1fr)',
                            'repeat(2, 1fr)',
                            'repeat(3, 1fr)',
                        ]}
                    >
                        <GridItem colSpan={[1, 2, 3]}>
                            <FadeUpBox>
                                <Heading fontSize="md">Web Development</Heading>
                            </FadeUpBox>
                        </GridItem>
                        <FadeUpBox>
                            <Capability
                                title="Blockchain"
                                src={ethereum}
                                info="Ethereum blockchain development with Solidity."
                            />
                        </FadeUpBox>
                        <FadeUpBox delay={delay1}>
                            <Capability
                                title="Custom Website"
                                src={newSite}
                                info="Completely custom built using React."
                            />
                        </FadeUpBox>
                        <FadeUpBox delay={delay2}>
                            <Capability
                                title="Redesign or Rebuild"
                                src={redesign}
                                info="Redesign or adding to a current site."
                            />
                        </FadeUpBox>
                        <GridItem colSpan={[1, 2, 3]}>
                            <FadeUpBox>
                                <Heading pt={8} fontSize="md">
                                    Mobile Development
                                </Heading>
                            </FadeUpBox>
                        </GridItem>
                        <FadeUpBox>
                            <Capability
                                title="iOS"
                                src={ios}
                                info="Native iOS using Swift and Xcode."
                            />
                        </FadeUpBox>
                        <FadeUpBox delay={delay1}>
                            <Capability
                                title="Android"
                                src={android}
                                info="Android development with Java."
                            />
                        </FadeUpBox>
                        <FadeUpBox delay={delay2}>
                            <Capability
                                title="Cross Platform"
                                src={cross}
                                info="React Native, Flutter for cross platform development."
                            />
                        </FadeUpBox>
                    </Grid>
                </Flex>
            </ResponsiveBlock>
        </Box>
    )
}

const Capability = ({ title, info = '', src = '', ...props }) => {
    const theme = useTheme()

    const MotionFlex = CreateMotionComponent(Flex)

    return (
        <MotionFlex
            direction="column"
            w="100%"
            h="100%"
            justify="space-between"
            align="center"
            borderRadius="10px"
            boxShadow="base"
            bg="white"
            {...props}
            px={15}
        >
            <Box w={'60%'} m={5} mt={10}>
                <Image
                    src={src}
                    w="100%"
                    h="100%"
                    objectFit="cover"
                    objectPosition="center"
                    borderRightRadius="inherit"
                />
            </Box>

            <Box py={8} px={4} w="100%">
                <Heading mb={2} fontSize="sm">
                    {title}
                </Heading>
                <Text color="gray.500">{info}</Text>
            </Box>
        </MotionFlex>
    )
}

export default ServicesWhat

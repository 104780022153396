import React from 'react'
import {
    Link,
    Box,
    SimpleGrid,
    Flex,
    Image,
    Heading,
    Text,
    useTheme,
    useBreakpointValue,
} from '@chakra-ui/react'

import CreateMotionComponent, {
    FadeUpBox,
} from '~components/shared/custom-animation'
import ResponsiveBlock from '~components/shared/responsive-block'

const technologies = [
    {
        name: 'ReactJS',
        logoUrl:
            'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/768px-React-icon.svg.png',
        url: 'https://reactjs.org/',
    },
    {
        name: 'Firebase',
        logoUrl: 'https://miro.medium.com/max/600/1*R4c8lHBHuH5qyqOtZb3h-w.png',
        url: 'https://firebase.google.com/',
    },
    {
        name: 'React Native',
        logoUrl:
            'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/768px-React-icon.svg.png',
        url: 'https://reactnative.dev/',
    },
    {
        name: 'GraphQL',
        logoUrl:
            'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/338px-GraphQL_Logo.svg.png',
        url: 'https://graphql.org/',
    },
    {
        name: 'Express.js',
        logoUrl:
            'https://upload.wikimedia.org/wikipedia/commons/6/64/Expressjs.png',
        url: 'https://expressjs.com/',
    },
    {
        name: 'Node.js',
        logoUrl:
            'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/1920px-Node.js_logo.svg.png',
        url: 'https://nodejs.org/en/',
    },
    {
        name: 'NextJS',
        logoUrl:
            'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Nextjs-logo.svg/330px-Nextjs-logo.svg.png',
        url: 'https://nextjs.org/',
    },
    {
        name: 'Solidity',
        logoUrl: 'https://docs.soliditylang.org/en/develop/_images/logo.svg',
        url: 'https://docs.soliditylang.org/en/develop/',
    },
]

const OurTechnologies = () => {
    const theme = useTheme()
    const shouldFadeUp = useBreakpointValue({ base: false, md: true })

    return (
        <ResponsiveBlock py="20px" bg="gray.50">
            <FadeUpBox>
                <Heading fontSize={['lg', 'xl']} textAlign="center" mb={3}>
                    Our Technologies
                </Heading>
                <Text
                    fontSize={['sm', 'md']}
                    textAlign="center"
                    color="gray.600"
                    pb={12}
                >
                    What we use every day to go from idea to impact.
                </Text>
            </FadeUpBox>
            <SimpleGrid columns={[2, 3, 4, 4, 4]} gap="24px" pb={8}>
                {technologies.map((technology, index) => (
                    <FadeUpBox delay={shouldFadeUp ? index * 0.2 : 0}>
                        <Link href={technology.url} isExternal>
                            <Flex
                                transition="0.3s"
                                _hover={{
                                    transform: 'scale(1.05)',
                                    boxShadow: theme.shadows.lg,
                                }}
                                h="200px"
                                flexDir="column"
                                justify="center"
                                align="space-evenly"
                                borderWidth={0.5}
                                borderColor="rgb(245, 245, 245)"
                                boxShadow="md"
                                borderRadius="xl"
                                bg="white"
                            >
                                <Box w="100%" h="70%">
                                    <Image
                                        padding={4}
                                        borderTopRadius="xl"
                                        src={technology.logoUrl}
                                        w="100%"
                                        h="100%"
                                        objectFit="contain"
                                    />
                                </Box>
                                <Flex justify="center" align="center">
                                    {technology.name}
                                </Flex>
                            </Flex>
                        </Link>
                    </FadeUpBox>
                ))}
            </SimpleGrid>
        </ResponsiveBlock>
    )
}

export default OurTechnologies

import React from 'react'

import SEO from '~components/shared/SEO'
import ServicesHeader from '~components/services/services-header'
import ServicesWhat from '~components/services/services-what'
import Comparison from '~components/services/comparison'
import Guarantees from '~components/services/guarantees'
import Process from '~components/services/process'
import OurTechnologies from '~components/services/our-technologies'

export default function ServicesPage() {
    return (
        <>
            <SEO
                title={'Services'}
                description={'See what we can offer you.'}
            />
            <ServicesHeader />
            <ServicesWhat />
            <OurTechnologies />
            <Process />
            <Comparison />
            <Guarantees />
        </>
    )
}

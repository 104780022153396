import React from 'react'

import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'

import { FadeUpBox } from '~components/shared/custom-animation'

const ComparisonBlock = ({
    title,
    img,
    leftLabel,
    leftText,
    rightLabel,
    rightText,
}) => {
    return (
        <FadeUpBox>
            <Flex direction="column" py={8}>
                <Flex
                    w="100%"
                    justify="center"
                    pb={{ base: 8, md: 4 }}
                    align="center"
                >
                    <Image
                        display={{ md: 'none' }}
                        boxSize={{ base: '36px' }}
                        mr={3}
                        src={img}
                    />
                    <Heading fontSize="lg" color="black">
                        {title}
                    </Heading>
                </Flex>
                <Flex
                    justify="space-evenly"
                    direction={{ base: 'column', md: 'row' }}
                >
                    <Flex w={{ base: '100%', md: '33%' }} direction="column">
                        <Flex align="center">
                            <Flex direction="column" align={{ md: 'center' }}>
                                <Heading
                                    fontSize="md"
                                    pb={{ base: 2, md: 4 }}
                                    _before={{
                                        base: {
                                            content: `'Them: '`,
                                            color: '#f74734',
                                        },
                                        md: { display: 'none' },
                                    }}
                                >
                                    {leftLabel}
                                </Heading>
                                <Text
                                    align={{ base: 'left', md: 'center' }}
                                    color="gray.500"
                                >
                                    {leftText}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex
                        w={{ base: '0%', md: '33%' }}
                        align="center"
                        justify="space-evenly"
                    >
                        <Text
                            display={{ base: 'none', md: 'block' }}
                            fontSize="xl"
                            borderRadius="50%"
                            color="brand.500"
                            _after={{ content: '"<"' }}
                        />
                        <Image
                            boxSize={{ base: '36px', md: '72px' }}
                            filter="opacity(50%)"
                            src={img}
                        />
                        <Text
                            display={{ base: 'none', md: 'block' }}
                            fontSize="xl"
                            borderRadius="50%"
                            color="#419212"
                            _after={{ content: '">"' }}
                        />
                    </Flex>
                    <Flex w={{ base: '100%', md: '33%' }} direction="column">
                        <Flex align="center">
                            <Flex direction="column" align={{ md: 'center' }}>
                                <Heading
                                    _before={{
                                        base: {
                                            content: '"Us: "',
                                            color: '#419212',
                                        },
                                        md: { display: 'none' },
                                    }}
                                    fontSize="md"
                                    pb={{ base: 2, md: 4 }}
                                >
                                    {rightLabel}
                                </Heading>
                                <Text
                                    align={{ base: 'left', md: 'center' }}
                                    color="gray.500"
                                >
                                    {rightText}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </FadeUpBox>
    )
}

export default ComparisonBlock
